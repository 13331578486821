import React from 'react'
import { NotFound } from '../components/NotFound/NotFound'


const NoEncontradoPage = () => {
    return (
        <NotFound />
    )
}

export default NoEncontradoPage;
